import { useSupabase } from '@wision/supabase';
import { useMutation, useQuery, useQueryClient  } from '@tanstack/vue-query';
import { withUseQuery } from '../../helpers';
import { jwtDecode } from 'jwt-decode';

type Options = {
  onSuccess?: () => void
  onError?: () => void
}

type JWT = {
  session_id: string
}

const decodeJwt = (token: string): JWT => {
  const decoded = jwtDecode(token) as { session_id: string };
  return decoded;
};

export const sessionQueryKyes = {
  sessionId: 'sessionId',
  session: 'session',
  sessions: 'sessions',
  currentOrganization: 'currentOrganization'
};

export const useGetSessionId = () => {
  const supabase = useSupabase();

  return useQuery({
    queryKey: [sessionQueryKyes.sessionId],
    queryFn: async () => {
      const session = await supabase.auth.getSession();

      if (session.error) throw new Error(session.error?.message);

      const accessToken = session.data.session?.access_token;
      if (!accessToken) 
        throw new Error('No access token found');

      const sessionId = decodeJwt(accessToken).session_id;

      return sessionId;
    }
  });
};

export const useGetCurrentOrganization = () => {
  const supabase = useSupabase();

  return useQuery({
    queryKey: [sessionQueryKyes.currentOrganization],
    queryFn: withUseQuery(async () => {
      
      return await supabase.rpc('get_current_organization');
    })
  });
};

export const useGetSession = () => {
  const supabase = useSupabase();

  return useQuery({
    queryKey: [sessionQueryKyes.session],
    queryFn: withUseQuery(
      async () => {
        const session = await supabase.auth.getSession();

        return await supabase.from('sessions').select('*').eq('id', session.data.session?.user.id ?? '').single();
      }
    )
  });
};

export const useSetCurrentOrganization = (options?: Options) => {
  const supabase = useSupabase();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (organizationId: string) => {
      const session = await supabase.auth.getSession();
      const accessToken = session.data.session?.access_token;
      if (!accessToken) 
        throw new Error('No access token found');

      const sessionId = decodeJwt(accessToken).session_id;

      if (session.error) throw new Error(session.error?.message);

      const { data, error } = await supabase.from('sessions').upsert({
        id: sessionId,
        current_organization: organizationId,
        user_id: session.data.session?.user.id
      });
      
      if (error) throw new Error(error.message);

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [sessionQueryKyes.session]
      });
      if (options?.onSuccess) options.onSuccess();
    },
    onError: () => {
      if (options?.onError) options.onError();
    }
  });
};

export const useGetSessions = () => {
  const supabase = useSupabase();

  return useQuery({
    queryKey: [sessionQueryKyes.sessions],
    queryFn: withUseQuery(async () => await supabase.rpc('get_user_sessions'))
  });
};
