import { PostgrestError, PostgrestSingleResponse  } from '@supabase/supabase-js';
import { Database } from './types';

export type Tables<T extends keyof Database['public']['Tables']> =
    Database['public']['Tables'][T]['Row']
export type Enums<T extends keyof Database['public']['Enums']> =
    Database['public']['Enums'][T]

export type DbResult<T> = T extends PromiseLike<infer U> ? U : never
export type DbResultOk<T> = T extends PromiseLike<{ data: infer U }>
    ? Exclude<U, null>
    : never
export type DbResultErr = PostgrestError

export function withUseQuery<T>(func: () => Promise<PostgrestSingleResponse<T>>) {
  return async () => {
    const { data, error } = await func()
    if (error) throw new Error(error.message)

    return data
  }
}
